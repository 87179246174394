












































import {
  Component, Vue, Watch, Prop
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/lib-api';
import { GroupsAction, GroupsActionType, GroupsState } from '@/store/modules/groups/types';
import { AppState } from '@/store/modules/app/types';
import { sort } from '@/utils/sort';
import { focusOnNewItemNameInput } from '@/utils/focus_on_input';
import { nameFilter } from '@/utils/name_filter';
import EditableListItem from '@/components/base/EditableListItem.vue';

const groups = namespace('groups');
const app = namespace('app');

@Component({
  components: {
    EditableListItem,
  },
})
export default class EditableGroupsList extends Vue {
  modifiedGroup: Models.Groups.Model | null = null;

  dialog = false;

  deleteData: Models.Groups.Model | null = null;

  key = 1;

  showNameTooltip: boolean = false;

  itemName = '';

  @Prop(Boolean) readonly permissionCheckWrite!: boolean;

  @Prop(Boolean) readonly permissionCheckDelete!: boolean;

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @groups.State
  groups!: GroupsState['groups'];

  @groups.State
  selectedGroupID!: GroupsState['selectedGroupID'];

  @groups.Action(GroupsAction.SetSelectedGroupID)
  setSelectedGroupID!: GroupsActionType['SET_SELECTED_GROUP_ID']

  @groups.Action(GroupsAction.SetSelectedGroupUuid)
  setSelectedGroupUuid!: GroupsActionType['SET_SELECTED_GROUP_UUID']

  @groups.Action(GroupsAction.AddGroupPlaceholder)
  addPlaceholderGroup!: GroupsActionType['ADD_GROUP_PLACEHOLDER']

  @groups.Action(GroupsAction.RemoveGroupPlaceholder)
  removePlaceholderGroup!: GroupsActionType['REMOVE_GROUP_PLACEHOLDER']

  @groups.Action(GroupsAction.DeleteGroup)
  deleteGroup!: GroupsActionType['DELETE_GROUP']

  @groups.Action(GroupsAction.CreateGroup)
  createGroup!: GroupsActionType['CREATE_GROUP']

  @groups.Action(GroupsAction.UpdateGroup)
  updateGroup!: GroupsActionType['UPDATE_GROUP']

  get isPlaceholderGroupInStore() {
    return this.groups.some((g) => !g.id);
  }

  get groupNamesArray() {
    const groupNames: string[] = [];
    this.groups.forEach((g: Models.Groups.Model) => {
      groupNames.push(g.name);
    });
    return groupNames.filter((g) => g !== this.itemName);
  }

  @Watch('modifiedGroup')
  onModifiedGroupChange() {
    this.setSelectedGroupID(null);
    this.setSelectedGroupUuid(null);
  }

  filteredGroups(searchPhrase: string) {
    const sortedGroups = [...this.groups].sort((a, b) => sort(a.name, b?.name));
    return sortedGroups.filter((g) => nameFilter(g.name, searchPhrase));
  }

  isModifiedGroup(groupId: number) {
    return this.modifiedGroup && this.modifiedGroup.id === groupId;
  }

  isSelectedGroup(groupId: number) {
    return this.selectedGroupID === groupId;
  }

  eventHandlers(group) {
    return {
      select: () => this.selectItem(group),
      edit: () => this.editItem(group),
      discard: this.discard,
      save: this.save,
      modalAction: () => {
        this.dialog = true;
        this.deleteData = group;
      }
    };
  }

  deleteModal() {
    // eslint-disable-next-line
    this.deleteData && (this.deleteItem(this.deleteData));
    this.deleteData = null;
    this.dialog = false;
  }

  closeModal() {
    this.dialog = false;
    this.deleteData = null;
  }

  selectItem(group: Models.Groups.Model) {
    if (this.userActionStatus.status === 'pending'
      || this.isPlaceholderGroupInStore
      || this.modifiedGroup) return;
    if (this.selectedGroupID && this.selectedGroupID === group.id) {
      this.setSelectedGroupID(null);
      this.setSelectedGroupUuid(null);
    } else {
      this.setSelectedGroupID(group.id!); // temp
      this.setSelectedGroupUuid(group.uuid!);
    }
  }

  editItem(group: Models.Groups.Model) {
    this.modifiedGroup = { ...group };
    this.itemName = this.modifiedGroup.name;
    focusOnNewItemNameInput();
  }

  deleteItem(group: Models.Groups.Model) {
    this.deleteGroup(group.uuid as string);
  }

  save() {
    if (!this.modifiedGroup) {
      return;
    }
    if (!this.modifiedGroup.name || this.groupNamesArray.includes(this.modifiedGroup.name)) {
      focusOnNewItemNameInput();
      this.showNameTooltip = true;
      return;
    }
    if (!this.modifiedGroup.id) {
      this.showNameTooltip = false;
      this.createGroup(this.modifiedGroup)
        .catch(() => {
          this.removePlaceholderGroup();
        })
        .finally(() => {
          this.modifiedGroup = null;
        });
    } else {
      this.updateGroup(this.modifiedGroup)
        .catch(() => {
          this.key += 1;
        })
        .finally(() => {
          this.modifiedGroup = null;
        });
    }
    this.removePlaceholderGroup();
  }

  discard(): void {
    if (!this.modifiedGroup) {
      return;
    }
    if (!this.modifiedGroup.id) {
      this.removePlaceholderGroup();
    }
    this.modifiedGroup = null;
    this.showNameTooltip = false;
    this.key += 1;
  }

  addGroup(): void {
    if (!this.groups.some((g) => !g.id)) {
      this.setSelectedGroupID(null);
      this.setSelectedGroupUuid(null);
      this.addPlaceholderGroup();
      this.$nextTick(() => {
        this.modifiedGroup = JSON.parse(JSON.stringify(this.groups.find((g) => !g.id)));
      });
    }
    focusOnNewItemNameInput();
  }

  mounted() {
    if (!this.selectedGroupID && this.groups.length) {
      const { id, uuid } = this.groups.sort((a, b) => sort(a.name, b.name))[0];
      this.setSelectedGroupID(id!);
      this.setSelectedGroupUuid(uuid!);
    }
    const placeholder = this.groups.find((g) => !g.id);
    if (placeholder) {
      this.modifiedGroup = JSON.parse(JSON.stringify(placeholder));
    }
  }

  destroyed() {
    this.setSelectedGroupID(null);
    this.setSelectedGroupUuid(null);
    this.removePlaceholderGroup();
  }
}
